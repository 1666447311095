





























import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import * as types from '@/store/account/types';

@Component
export default class Verify extends Vue {

  @Action('verify', { namespace: 'account' }) private verify!: types.VerifyAction;

  private completed: boolean = false;
  private error: boolean = false;
  private verifying: boolean = false;

  private mounted() {
    const token: string = this.$route.params.verifyToken;
    const uidb64: string = this.$route.params.uid;
    this.verifying = true;
    this.verify({ token, uidb64 }).catch(() => {
      this.error = true;
    }).finally(() => {
      this.verifying = false;
      this.completed = true;
    });
  }
}
